/***** Typografias *****/

@font-face {
    font-family: 'Monserrat-Regular';
    src: url('../fonts/Montserrat-Regular.otf') format('truetype');
} 

@font-face {
    font-family: 'Monserrat-Bold';
    src: url('../fonts/Montserrat-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Monserrat-Black';
    src: url('../fonts/Montserrat-Black.otf') format('truetype');
}

@font-face {
    font-family: 'Monserrat-ExtraBold';
    src: url('../fonts/Montserrat-ExtraBold.otf') format('truetype');
}

@font-face {
    font-family: 'Monserrat-Medium';
    src: url('../fonts/Montserrat-Medium.otf') format('truetype');
}

@font-face {
    font-family: 'Monserrat-ExtraLight';
    src: url('../fonts/Montserrat-ExtraLight.otf') format('truetype');
}


@font-face {
    font-family: 'Xtradex';
    src: url('../fonts/Xtradex.ttf') format('truetype');
}



/***** End Typografias *****/

body {
    background-image: url('../images/backgroundStoner.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: white !important;
}

ul li{
    list-style-type: none;
    text-align: left;
    margin-bottom: 10px;
}

.section{
    margin:90px 0;
}

.titulo-seccion {
    font-family: 'Monserrat-ExtraBold', sans-serif;
    font-size: 60px;
    color: #ffffff;
    margin-bottom: 80px;
    text-align: center;
    
}

.subtitulo-seccion {
    font-family: 'Monserrat-ExtraBold', sans-serif;
    font-size: 35px;
    color: #00ffff;
    text-align: center;
    margin-bottom: 40px;
    
}

.margin-text  {
    -webkit-text-stroke: 1px white; 
    color: transparent; 
}

.description {
    font-family: 'Monserrat-Regular', sans-serif;
    font-size: 14px;
    color: white;
    padding: 0 10%;
    text-align: center;
}

.banner2{
    font-family: 'Xtradex', sans-serif;
    font-size: 50px;
    text-align: center;
    color: white;
    margin: 90px 0 60px 0;
    transform: matrix(1, -0.04, 0.04, 1, 0, 0);
}


/***** NavBar *****/

.logoHeader img {
    width: 70% ;
    float: left;
}

.menuHeader {
    font-family: 'Monserrat-bold', sans-serif !important;
    color: white !important;
    font-size: 20px !important;
    margin-right: 10px;
}

.menuHeader:hover,
.rrssHeader:hover,
.rrssFooter:hover{
    color: #FF00FF !important;
}

.calypso{
    color:#00FFFF;
}

.yellow {
    color: #fece00;
}

.purple {
    color: #FF00FF;
}


.rrssHeader{
    font-size: 30px;
    margin-right: 10px;
    color:#00FFFF;
    
}
.navbar-toggler{
    background-color: #00FFFF !important;
    position: absolute;
    top: 15px;
    right: 0;
}

.d-flex.float-right {
    display: flex;
    justify-content: center;
}


/***** End Navbar *****/


/***** triciklo-banner *****/
.HeroBannerTriciklo {
    display: flex;
    position: relative;
}

.image-container-left {
    position: absolute;
    top: 0;
    width: 23px;
}

.image-container-center{
    max-width: 100%;
    width: 60%;
    position: relative;
}

.custom-button{
    position:absolute;
    width: 155px;
    height: 35px;
    transform: matrix(1, -0.04, 0.04, 1, 0, 0);
    right: 50%;
    top: 55%;
    background: transparent linear-gradient(90deg, #FF00FF 0%, #FE05F9 9%, #FE14E8 22%, #FE2ECC 36%, #FD51A5 52%, #FC7F73 68%, #FBB637 86%, #FBE900 100%);
    color: white !important;
    font-family: 'Monserrat-Bold', sans-serif;
    font-size: 20px;
    justify-content: center;
    transition: background-color 0.5s ease;
    cursor: pointer;
}

.custom-button:hover {
    background: #00FFFF; 
}

.custom-button button{ 
    border-radius: 0% !important;
}


.image-container-right {
    width: 200px; 
    height: 100px;
    z-index: 4;
    position: absolute;
    right: 0;
}

.waves {
    width: 80%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

/***** End triciklo-banner *****/

/***** Nosotros *****/

.background-nosotros { 
    background-image: url('../images/borderN.png');
    background-size: cover;
    
    height: 50vh;
    padding: 40px;
    
}

.description-about {
    font-family: 'Monserrat-Regular', sans-serif;
    font-size: 16px;
    color: white;
    padding: 0 10%;
    text-align: center;
}

.title-service {
    color:#fff;
    font-family: 'Xtradex', sans-serif;
    font-size: 100px;
    text-align: center;
    margin-bottom: 40px;
}



/***** End Nosotros *****/

/***** Clientes *****/
    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        }
        
        .swiper-slide {
            background-position: center;
            background-size: cover;
            width: 300px;
            height: 300px;
        }
        
        .swiper-slide img {
            display: block;
            width: 100%;
        }

        .marcas {
        width: 200px;
        height: 150px;
        }

/***** End Clientes *****/

/***** Footer *****/
.imgFooter{
    position: absolute;
    width: 30%;
    left: -40px;
}

.contacto img{
    width: 100%;
}
.contacto-triciklo{
    font-family: 'Monserrat-Bold', sans-serif;
    color: white;
    font-size: 25px;
    text-decoration: none;
}

.contRrss a{
    text-decoration: none;
    color: white;
}

.rrssFooter{
    text-align: right !important ;
    font-family: 'Monserrat-Regular', sans-serif;
    font-size: 15px;
}

.margen-calypso {
    background-image: linear-gradient(to left, #00ffff 30%, transparent 70%);
    background-size: 100% 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
}

.direccion{
    padding-top: 60px;
    font-size: 13px;
}

.slogan-triciklo {
    font-family: 'Xtradex', sans-serif;
    color: white;
    font-size: 35px;
    text-transform: uppercase;
}


/***** End Footer *****/

@media screen and (max-width: 768px) {
    .section {
        margin: 60px 0;
    }


    .logoHeader img {
        width: 40% ;
    }

    .image-container-center {
        width: 100%;
    }

    .image-container-left {
        display: none;
    }

    .custom-button{
        right: 30%;
    }

    .banner2{
        font-size: 30px;
    }

    .titulo-seccion {
        font-size: 50px;
    }

    footer {
        display: flex;
        justify-content: center;
    }

    .image-container {
        flex: 0 0 auto;
        margin-right: 20px; /* Espacio entre la imagen y el contenido */
    }

    .content-container {
        flex: 1 1 auto;
    }

    .contImg{
        position:relative;
    }
    .imgFooter{
        position: absolute;
        width: 100%;
        left: -250px;
        z-index: 10;
        top: -100px;
    }

    .contacto.direccion {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 20%;
        padding-top: 20px;
    }

    .contacto img{
        width: 50%;
    }

    .contacto-triciklo{
        font-size: 15px;
        text-decoration: none;
    }

    .slogan-triciklo {
        font-size: 25px;
    }

    .direccion { 
        padding-top: 30px;
    }

    .contRrss.direccion ul {
        display: flex;
        justify-content: center;
        margin-right: 10px;
        padding-top: 20px;
        font-size: 10px;
    }
    .rrssFooter{
        text-align: center ;
        font-family: 'Monserrat-Regular', sans-serif;
        font-size: 15px;
        margin-right: 10px;
    }

    }

    /*.gallery*/
.gallery{
    width: 100%;
    display: block;
    min-height: 100vh;
    padding: 100px 0;
  }
  .gallery .gallery-filter{
    padding: 0 15px;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  .gallery .gallery-filter .filter-item{
    color: #ffffff;
    font-size: 17px;
    border: 2px solid white;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 20px;
    margin-right: 8px;
    cursor: pointer;
    padding: 8px 20px 8px 20px;
    line-height: 1.2;
    transition: all 0.3s ease;
  }
  .gallery .gallery-filter .filter-item.active{
    color: white;
    border-color : #16b5ef;
    background: #16b5ef;
  }
  .gallery .gallery-item{
    width: calc(100% / 3);
    padding: 15px;
  
  }
  .gallery .gallery-item-inner img{
    width: 100%;
    border: 3px solid #d4dad9;
  }
  .gallery .gallery-item.show{
    animation: fadeIn 0.5s ease;
  }
  @keyframes fadeIn{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .gallery .gallery-item.hide{
    display: none;
  }

  /*whatsappButtom */
  .whatsapp-button {
    position: fixed; 
    right: 50px; 
    bottom: 30px; 
    z-index: 1000;
    background: transparent linear-gradient(90deg, #FF00FF 0%, #FE05F9 9%, #FE14E8 22%, #FE2ECC 36%, #FD51A5 52%, #FC7F73 68%, #FBB637 86%, #FBE900 100%);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex; /* Habilita flexbox */
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    cursor: pointer;
}

.whatsapp-button:hover {
    background-color: #128C7E;
}

.fa-whatsapp {
    color: white;
    width: 45px;
    height: 45px;
}

  
  /*responsive*/

  @media(max-width: 491px){
    .gallery .gallery-item{
      width: 50%;
    }
    .image-container-right {
        display: none;
    }

    .whatsapp-button {
        right: 70px;
    }
  }

  @media(max-width: 467px){
      .gallery .gallery-item{
      width: 100%;
    }
    .gallery .gallery-filter .filter-item{
      margin-bottom: 10px;
    }
  }
